var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-overlay',{attrs:{"z-index":100,"value":_vm.isLoading}},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"indeterminate":"","size":"64"}})],1),_c('v-app-bar',{attrs:{"id":"home-app-bar","app":"","color":_vm.$vuetify.theme.isDark ? 'black' : 'white',"elevation":"1","elevate-on-scroll":"","height":"80","extension-height":"80"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex flex-wrap",class:_vm.$vuetify.breakpoint.smAndDown
                ? 'flex-column align-start'
                : 'align-center'},[_c('v-btn',{staticClass:"pa-3",class:_vm.$vuetify.breakpoint.smAndDown
                  ? 'text-subtitle-2'
                  : 'text-h5',staticStyle:{"background-color":"black","height":"100%","color":"#d4af37","width":"100%","padding":"20px !important"},attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'Jazz' })}}},[_c('span',{domProps:{"innerHTML":_vm._s('\u266C')}}),_c('b',[_vm._v("Jazz Weekend Trails and Gigs")]),_c('span',{domProps:{"innerHTML":_vm._s('\u266C')}})])],1)])],1)]},proxy:true}])},[_c('base-img',{staticClass:"hidden-xs-only",attrs:{"src":_vm.$vuetify.theme.isDark
        ? require(`@/assets/icon_black.png`)
        : require(`@/assets/icon_white.png`),"contain":"","max-width":"80","width":"100%"},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}}),_c('base-img',{attrs:{"src":_vm.$vuetify.theme.isDark
          ? require(`@/assets/LogoText.png`)
          : require(`@/assets/LogoTextWhite.png`),"contain":"","max-width":"200","width":"100%"},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}}),_c('v-spacer'),_c('div',[_c('v-tabs',{staticClass:"hidden-sm-and-down",attrs:{"optional":"","background-color":"transparent"}},_vm._l((_vm.items),function(name,i){return _c('v-tab',{key:i,staticClass:"font-weight-bold",attrs:{"to":{ name },"exact":name === 'Home',"ripple":false,"min-width":"96","text":""}},[_vm._v(" "+_vm._s(name)+" ")])}),1)],1),_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",on:{"click":function($event){_vm.drawer = !_vm.drawer}}})],1),_c('home-drawer',{attrs:{"items":_vm.items},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }